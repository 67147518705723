<template>
  <b-modal
    ref="setup-automation-modal"
    id="setup-automation-modal"
    no-close-on-backdrop
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <div>
        <h3 class="font-medium text-primary">
          {{ action === 'edit' ? $t('autoTopUp.setupAutomation.editAutomationTiTle') : $t('autoTopUp.setupAutomation.setupAutomationTiTle') }}
        </h3>
      </div>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="mt-1">
      <span>{{ $t('autoTopUp.setupAutomation.setupAutomationContent', { platformName: platformName }) }}
        <a
          v-if="guideToAutoTopUpAdAccount"
          class="text-link-color cursor-pointer"
          target="_blank"
          style="font-size: 13px; text-decoration:underline;"
          :href="guideToAutoTopUpAdAccount"
        >{{ $t('payment.balanceAllocationTable.textViewDetail') }}</a></span>
      <validation-observer
        ref="setupAutomation"
      >
        <b-form-group class="mt-1">
          <label class="form-label">
            {{ $t('autoTopUp.setupAutomation.TopUpAmount') }}
            <span class="text-danger">*</span>
          </label>
          <validation-provider
            #default="{ errors }"
            class="other-adreach"
            :name="$t('autoTopUp.setupAutomation.TopUpAmount')"
            :rules="{
              required,
              integerNumber,
              rangeAutoTopUpAmount: {min: minTopUp, max: maxTopUp},
            }"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="DollarSignIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model.number="topUpAmount"
                id="topup-amount"
                name="topup-amount"
                class="input-height input-topup-amount"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('autoTopUp.setupAutomation.TopUpAmountPlaceholder')"
                @keydown="onlyNumber"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label class="form-label">
            {{ $t('autoTopUp.setupAutomation.amountBelowThreshold') }}
            <span class="text-danger">*</span>
          </label>
          <validation-provider
            #default="{ errors }"
            class="other-adreach"
            :name="$t('autoTopUp.setupAutomation.amountBelowThreshold')"
            :rules="{
              required,
              integerNumber,
              rangeAutoTopUpAmount: {min: minThreshold, max: maxThreshold},
            }"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="DollarSignIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model.number="amountBelowThreshold"
                id="amount-below-threshold"
                name="amount-below-threshold"
                class="input-height input-amount-below-threshold"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('autoTopUp.setupAutomation.amountBelowThresholdPlaceholder')"
                @keydown="onlyNumber"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2">
          <div class="d-flex">
            <b-form-checkbox
              v-model="checked"
              :value="true"
              class="agree-policy-checkbox custom-control-success"
            />
            <span>
              {{ $t('autoTopUp.setupAutomation.agreePolicy', {platformName: platformName}) }}
              <a
                v-if="autoTopUpAdAccountPolicy"
                class="text-link-color cursor-pointer"
                :href="autoTopUpAdAccountPolicy"
                target="_blank"
              >{{ $t('autoTopUp.setupAutomation.textPrivacy') }}</a>
              <span v-else>{{ $t('autoTopUp.setupAutomation.textPrivacy') }}</span>
              {{ $t('autoTopUp.setupAutomation.textEcomdy', {platformName: platformName}) }}
            </span>
          </div>
        </b-form-group>

        <div class="btn-control d-flex justify-content-end pt-2">
          <btn-loading
            class="mr-2 border-0"
            variant-convert="btn-back"
            pill
            @click="hideModal"
          >
            {{ $t('autoTopUp.setupAutomation.cancel') }}
          </btn-loading>
          <btn-loading
            type="submit"
            variant-convert="btn-submit"
            pill
            :disabled="!checked"
            :loading="loading"
            @click.prevent="submitAutoTopUpAdAccount()"
          >
            <span>{{ action === 'edit' ? $t('autoTopUp.setupAutomation.edit') : $t('autoTopUp.setupAutomation.setup') }}</span>
          </btn-loading>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable global-require */
import {
  BModal,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import {
  required,
  integerNumber,
  rangeAutoTopUpAmount,
} from '@validations'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BtnLoading,
    // validations
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [envMixin, toastification],

  props: {
    accountSelected: {
      type: Object,
      required: true,
    },

    action: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      topUpAmount: null,
      amountBelowThreshold: null,
      checked: false,

      // validations
      required,
      integerNumber,
      rangeAutoTopUpAmount,
    }
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
    ...mapGettersAuth(['generalConfigs']),

    minTopUp() {
      return this.generalConfigs?.autoTopUpValidation?.MIN_AUTO_TOPUP_ADS_ACCOUNT || 10
    },
    maxTopUp() {
      return this.generalConfigs?.autoTopUpValidation?.MAX_AUTO_TOPUP_ADS_ACCOUNT || 10000
    },
    minThreshold() {
      return this.generalConfigs?.autoTopUpValidation?.MIN_THRESHOLD_AUTO_TOPUP_AMOUNT || 200
    },
    maxThreshold() {
      return this.generalConfigs?.autoTopUpValidation?.MAX_THRESHOLD_AUTO_TOPUP_AMOUNT || 10000
    },
  },

  watch: {
    accountSelected: {
      handler() {
        this.resetData()
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['autoTopUpAdAccount']),

    hideModal() {
      this.resetModal()
      if (this.action !== 'edit') {
        this.$emit('cancelModal', false)
      }
    },

    showModal() {
      this.$refs['setup-automation-modal'].show()
    },

    resetModal() {
      this.resetData()
      this.$refs.setupAutomation.reset()
      this.$refs['setup-automation-modal'].hide()
    },

    resetData() {
      this.topUpAmount = this.accountSelected?.autoTopUpSetting?.amount
      this.amountBelowThreshold = this.accountSelected?.autoTopUpSetting?.threshold
      if (this.action === 'edit') {
        this.checked = true
      } else {
        this.checked = false
      }
    },

    onlyNumber(e) {
      if (e.key.length === 1 && /\D/.test(e.key)) {
        e.preventDefault()
      }
    },

    async submitAutoTopUpAdAccount() {
      const success = await this.$refs.setupAutomation.validate()
      if (success) {
        const params = {
          autoTopUpSetting: [
            {
              // eslint-disable-next-line no-underscore-dangle
              adsAccountId: this.accountSelected._id,
              amount: this.topUpAmount,
              threshold: this.amountBelowThreshold,
              status: 'enable',
            },
          ],
        }
        await this.autoTopUpAdAccount(params)

        if (this.status) {
          this.toastSuccess(`${this.action === 'edit' ? this.$t('autoTopUp.setupAutomation.editSuccess') : this.$t('autoTopUp.setupAutomation.setUpSuccess')}`)
          this.$emit('fetchAdsAccounts')
          this.resetModal()
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.agree-policy-checkbox {
  .custom-control-input {
    cursor: pointer;
  }
}
</style>
<style lang="scss" scoped>
.agree-policy-checkbox {
  margin-top: 2px;
}
.text-link-color {
  color: #2667FF;
}
</style>
