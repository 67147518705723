<template>
  <b-modal
    ref="confirm-turn-off-auto-top-up-modal"
    id="confirm-turn-off-auto-top-up-modal"
    no-close-on-backdrop
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-end top-modal">
      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="hideModal"
      />
    </div>

    <div class="d-flex justify-content-center">
      <b-img
        rounded
        class="ml-1"
        :src="isFromTikTok ? require('@/assets/images/pages/payment/bg-confirm-turn-off-tiktok-app-center.png') : require('@/assets/images/pages/payment/bg-confirm-turn-off.png')"
        style="width: 180px"
      />
    </div>
    <div class="text-center mt-2">
      <h4 class="mt-1">
        {{ $t('autoTopUp.confirmTurnOffAutoTopUp.content') }}
      </h4>
      <div class="btn-control d-flex justify-content-center pt-2">
        <btn-loading
          class="mr-2 border-0"
          variant-convert="btn-back"
          pill
          @click="hideModal"
        >
          {{ $t('autoTopUp.setupAutomation.cancel') }}
        </btn-loading>
        <btn-loading
          type="submit"
          variant-convert="btn-submit"
          pill
          :loading="loading"
          @click.prevent="turnOffAutoTopup()"
        >
          <span>{{ $t('autoTopUp.confirmTurnOffAutoTopUp.btnSubmit') }}</span>
        </btn-loading>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { BImg } from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BImg,
    BtnLoading,
  },

  mixins: [toastification, envMixin],

  props: {
    accountSelected: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
    ...mapGettersAuth(['isTikTokAppCenter']),
  },

  methods: {
    ...mapActions(['autoTopUpAdAccount']),
    hideModal() {
      this.$refs['confirm-turn-off-auto-top-up-modal'].hide()
      this.$emit('cancelModal', true)
    },

    showModal() {
      this.$refs['confirm-turn-off-auto-top-up-modal'].show()
    },

    async turnOffAutoTopup() {
      const params = {
        autoTopUpSetting: [
          {
            // eslint-disable-next-line no-underscore-dangle
            adsAccountId: this.accountSelected._id,
            status: 'disable',
          },
        ],
      }
      await this.autoTopUpAdAccount(params)

      if (this.status) {
        this.toastSuccess(`${this.$t('autoTopUp.confirmTurnOffAutoTopUp.turnOffSuccess')}`)
        this.$emit('fetchAdsAccounts')
        this.$refs['confirm-turn-off-auto-top-up-modal'].hide()
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
