<template>
  <b-modal
    v-model="showModal"
    no-close-on-backdrop
    centered
    :title="$t('account.modalCreateAdAccount.titleModal')"
    hide-footer
    content-class="business-content"
    header-class="business-header"
    body-class="business-body"
    @close="$emit('close-modal')"
  >
    <div>
      <validation-observer ref="businessFormValidation">
        <b-form
          @submit.prevent="submit"
        >
          <b-form-group
            :label="$t('myAdAccountsPage.setupTab.step3.labelBusinessCenterName')"
            class="form-label"
          >
            <validation-provider
              #default="{ errors }"
              id="name"
              rules="required"
              :name="$t('myAdAccountsPage.setupTab.step3.labelBusinessCenterName')"
            >
              <b-input-group
                class="input-group-merge width-custom w-100"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model="businessName"
                  class="input-height"
                  :placeholder="$t('account.modalCreateAdAccount.placeHolderBusinessCenterName')"
                  :state="errors.length > 0 ? false : null"
                  @keyup="currentIndex = null"
                />
              </b-input-group>
              <small
                v-if="errors[0]"
                class="text-danger d-block"
                style="margin-top: 10px"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            rules="required"
            :name="$t('account.modalCreateAdAccount.labelTimezone')"
          >
            <b-form-group :state="errors.length > 0 ? false : null">
              <div>
                <div class="d-flex justify-content-between">
                  <label>{{ $t('account.modalCreateAdAccount.labelTimezone') }}</label>
                </div>
                <v-select
                  v-model="timeZone"
                  class="timezone-select"
                  :options="listTimeZone"
                  :clearable="false"
                  :placeholder="$t('account.modalCreateAdAccount.placeholderTimezone')"
                  @input="handleSelectTimeZone"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="20"
                      />
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </b-form-group>
          </validation-provider>

          <div
            v-if="errorStep2"
          >
            <error-tag :error="errorStep2" />
          </div>

          <btn-loading
            class="mt-2 btn-height"
            type="submit"
            variant-convert="btn-submit"
            pill
            :loading="loading"
          >
            {{ $t('account.modalCreateAdAccount.btnCreateNewAccount') }}
          </btn-loading>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { toastification } from '@core/mixins/toast'
import errorTag from '@/components/errorTag.vue'
import envMixin from '@/mixins/envMixin'
import vSelect from 'vue-select'

const { mapActions, mapGetters } = createNamespacedHelpers('adsAccount')

export default {
  components: {
    errorTag,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BtnLoading,
    vSelect,
  },

  mixins: [generalConfigsMixin, toastification, envMixin],

  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showModal: this.show,
      businessName: '',
      required,
      timeZone: '',
      errorStep2: '',
    }
  },

  computed: {
    ...mapGetters(['loading', 'message', 'status']),
  },

  watch: {
    show(value) {
      this.showModal = value
    },

    showModal(value) {
      if (!value) {
        this.$emit('close-modal')
      }
    },
  },

  created() {
    this.setTimezone()
  },

  methods: {
    ...mapActions(['createBusinessAccount', 'getBusinessAccount']),

    setTimezone() {
      if (this.isEmediaPlatform) {
        this.timeZone = '(UTC+00:00) Africa/Casablanca'
      } else {
        this.timeZone = ''
      }
    },

    handleSelectTimeZone(value) {
      this.timeZone = value
    },

    resetForm() {
      this.businessName = ''
      this.timeZone = ''
    },

    async submit() {
      this.$refs.businessFormValidation.validate().then(async success => {
        if (success) {
          await this.createBusinessAccount({ BCName: this.businessName, timezone: this.timeZone })
          if (this.status) {
            this.toastSuccess(this.$t('myAdAccountsPage.setupTab.step3.noticeCreateNewAccountSuccess'))
            this.resetForm()
            this.$emit('close-modal')
          } else {
            this.errorStep2 = this.message
            setTimeout(() => {
              this.$router.go()
            }, 4000)
          }

          await this.getBusinessAccount()

          this.$emit('refresh-page')
        }
      })
    },
  },
}
</script>

<style lang="scss">
.business-header {
  padding: 32px;
  padding-bottom: 0px;
  border-radius: var(--border-radius-base);

  .modal-title {
    font-size: 20px;
  }
  &.modal-header .close {
    padding: 0;
    background-color: transparent;
    top: 25px;
    font-size: 40px;
    position: absolute;
    right: 50px;
    transform: none;
    box-shadow: none;
    border-radius: 0px;
    &:hover {
      transform: none;
    }
  }
}

.business-body {
  padding: 32px 32px;
}

.business-content {
  border-radius: var(--border-radius-base);
}

.timezone-select {
  .vs__dropdown-toggle {
    height: 48px;
  }
  .custom-select {
    &:focus {
      border-color: #16213e;
    }
  }
}

.error-create-ads {
  margin-top: 24px;
  background: rgba(225, 28, 78, .1);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
  }
}
</style>
