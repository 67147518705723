<template>
  <div
    class="tabs-mf tabs-mf-custom tabs-my-ads-account py-2"
  >
    <b-tabs>
      <b-tab
        id="trigger-my-ad-account-approved"
        :active="tab === 'approved'"
        @click="onClickTab('approved')"
      >
        <template #title>
          <span
            id="trigger-my-ad-account-approved-child"
            class="tab-title"
          >{{ $t('myAdAccountsPage.approvedTab.textListAccount') }}</span>
        </template>
        <list-approval-account
          v-if="tab === 'approved'"
          @create-account="handleCreateAccount"
        />
      </b-tab>

      <!--      <b-tab-->
      <!--        v-if="!isSMBAgencyPlatform"-->
      <!--        id="trigger-my-ad-account-review"-->
      <!--        :active="tab === 'review'"-->
      <!--        @click="onClickTab('review')"-->
      <!--      >-->
      <!--        <template #title>-->
      <!--          <span-->
      <!--            id="trigger-my-ad-account-review-child"-->
      <!--            class="tab-title"-->
      <!--          >{{ $t('myAdAccountsPage.textInReview') }}</span>-->
      <!--        </template>-->
      <!--        <list-review-account-->
      <!--          v-if="tab === 'review'"-->
      <!--          @create-account="handleCreateAccount"-->
      <!--        />-->
      <!--      </b-tab>-->

      <b-tab
        id="trigger-my-ad-account-setting"
        :active="tab === 'settings'"
        @click="onClickTab('settings')"
      >
        <template #title>
          <span
            id="trigger-my-ad-account-setting-child"
            class="tab-title"
          >{{ $t('myAdAccountsPage.approvedTab.textCreateNewAccount') }}</span>
        </template>
        <div v-if="hasMaintainMode">
          <div class="mb-2 warning-message">
            <div>
              <h5 class="mb-2 title">
                {{ $t('home.maintenance.titleMaintenance') }}
              </h5>
              <p>
                {{ $t('home.maintenance.textHiThere') }} <span style="width: 30px">🙌</span>
              </p>
              <p>
                {{ $t('home.maintenance.firstContent') }}
              </p>
              <user-hint />
              <p>
                {{ $t('home.maintenance.finalContent') }}
              </p>
            </div>
          </div>
        </div>
        <setup-account
          v-else
          :step="step"
          @change-step="changeStep"
          @connect-tiktok="$emit('connect-tiktok')"
          @refresh-page="$emit('refresh-page')"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
} from 'bootstrap-vue'
import adsAccount from '@/mixins/adsAccount'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { toastification } from '@core/mixins/toast'
import UserHint from '@/components/UserHint.vue'
import SetupAccount from './components/SetupAccount.vue'
// import ListReviewAccount from './components/ListReviewAccount.vue'
import ListApprovalAccount from './components/ListApprovalAccount.vue'

export default {
  components: {
    UserHint,
    BTab,
    BTabs,
    SetupAccount,
    // ListReviewAccount,
    ListApprovalAccount,
  },

  mixins: [adsAccount, envMixin, stepGuideMixin, toastification],

  props: {
    hasAdsAccount: {
      type: Boolean,
      required: true,
      default: false,
    },

    step: {
      type: Number,
      required: true,
      default: 1,
    },

    connectMessage: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      tab: this.$route.query.tab || 'approved',
    }
  },

  watch: {
    $route: {
      handler(route) {
        if (route?.query?.tab) {
          this.tab = route?.query?.tab
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.tab = this.$route.query.tab || 'approved'
  },

  methods: {
    onClickTab(value) {
      this.tab = value
      this.$router.push(`/my-ads-account?tab=${value}`)
    },

    handleCreateAccount() {
      this.$router.push('/my-ads-account?tab=settings')
      this.tab = 'settings'
    },

    changeStep(step) {
      this.$emit('change-step', step)
    },
  },
}
</script>
<style lang="scss">
.tabs-my-ads-account .nav-tabs {
  @media(max-width:767px) {
    margin-bottom: 24px;

    .nav-item {
      margin-right: 1rem;
    }
  }
}
</style>
<style scoped lang="scss">
  .warning-message{
    background: rgba(251, 176, 59, 0.1);
    border-radius: 10px;
    padding: 22px;
  }
</style>
