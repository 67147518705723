<template>
  <div v-if="hasUserGuide">
    <div
      class="d-lg-none text-learn-more text-success cursor-pointer d-flex align-items-center mb-1"
      @click="handleShowDrawer"
    >
      <feather-icon
        size="16"
        icon="FileTextIcon"
        class="text-success"
        style="margin-right: 5px"
      />
      {{ $t('userGuide.help') }}
    </div>
    <div
      class="user-guide-container"
      :class="[showDrawer ? 'show-drawer' : 'hide-drawer', isClick ? 'd-block' : 'd-none d-lg-block']"
      @focusout="handleShowDrawer"
    >
      <div
        v-for="userGuide in listUserGuide"
        :key="userGuide.id"
      >
        <div
          v-if="$route.name === userGuide.id"
          class="user-guide-content"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="mb-1 ic-lightbulb">
                <b-img
                  class="img-lightbulb"
                  :width="36"
                  fluid
                  :src="require('@/assets/images/icons/ic-lightbulb.svg')"
                  alt="Popup guide"
                />
              </div>
              <p
                class="font-weight-600 text-title ml-1"
              >
                {{ $t('userGuide.help') }}
              </p>
            </div>
            <feather-icon
              size="20"
              icon="XIcon"
              class="cursor-pointer d-lg-none"
              @click="handleShowDrawer"
            />
          </div>
          <hr class="my-0 d-lg-none">
          <div class="user-guide-scroll">
            <div class="mt-2">
              <p
                v-if="userGuide.title"
                class="font-weight-600"
              >
                {{ userGuide.title }}

              </p>
              <div
                v-for="(guide, index) in userGuide.listGuide"
                :key="index"
              >
                <div
                  v-if="!guide.isHide"
                  :id="guide.id"
                  :data-testid="guide.id"
                  class="step-guide-content mb-1 d-flex cursor-pointer"
                  @click="goTo(guide.link)"
                >
                  <feather-icon
                    size="16"
                    width="16"
                    icon="SettingsIcon"
                    class="setting-icon"
                    :class="guide.isActive ? 'setting-icon-active' : 'setting-icon'"
                  />
                  <div style="width: fit-content">
                    <p
                      class="mb-0 guide-content font-weight-600"
                      :class="guide.isActive ? 'user-guide-title-active' : 'user-guide-title'"
                    >
                      {{ guide.guide }}
                    </p>
                    <p class="mb-0 font-12 guide-description">
                      {{ guide.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-1">
              <p
                v-if="userGuide.listQuestion && questions(userGuide.listQuestion).length > 0"
                class="font-weight-600 pt-1"
              >
                {{ userGuide.titleQuestion }}
              </p>
              <p
                v-for="(question, index) in questions(userGuide.listQuestion)"
                :key="index"
                :id="question.id"
                :data-testid="question.id"
                class="cursor-pointer text-question"
                @click="goTo(question.link)"
              >
                {{ index+1 }}. <span class="question">{{ question.question }}</span>
              </p>
            </div>
            <div class="pt-1">
              <b-img
                class="mr-50"
                fluid
                width="20"
                src="@/assets/images/icons/ic-chat-message.svg"
                alt="Popup guide"
              />
              <user-hint
                class="font-14"
                :hide-icon="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adsAccount from '@/mixins/adsAccount'
import { BImg } from 'bootstrap-vue'
import UserHint from '@/components/UserHint.vue'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    BImg,
    UserHint,
  },

  mixins: [adsAccount, envMixin],
  data() {
    return {
      showDrawer: false,
      isClick: false,
    }
  },

  computed: {
    ...mapGetters(['isTikTokAppCenter']),
    listUserGuide() {
      return [
        {
          id: 'update-information-business',
          titleQuestion: this.$t('userGuide.mostViewedVerifyAccountArticles'),
          listGuide: [
            {
              id: 'click-to-kickstart',
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToVerifyingAccount'),
              link: this.guideVerifyAccount,
              isActive: true,
              isHide: !this.guideVerifyAccount,
            },
          ],
          listQuestion: [
            {
              id: 'question1',
              question: this.$t('userGuide.whyVerifyAccount'),
              link: 'https://docs.ecomdymedia.com/ecomdy-platform/flow/verify#1-why-verify-account',
            },
            {
              id: 'question2',
              question: this.$t('userGuide.howToPassVerificationProcess'),
              link: 'https://docs.ecomdymedia.com/ecomdy-platform/flow/verify#3-how-to-pass-the-verification-process',
            },
            {
              id: 'question3',
              question: this.$t('userGuide.howToSubmitProductLink'),
              link: 'https://docs.ecomdymedia.com/ecomdy-platform/flow/verify#4-submit-product-link',
            },
          ],
        },
        {
          id: 'add-fund',
          titleQuestion: this.$t('userGuide.mostViewedPaymentArticles'),
          listGuide: [
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'PayPal' }),
              link: this.guideToTopUpPaypal,
              isHide: this.$route.query.tab !== 'paypal' || !this.guideToTopUpPaypal || this.isTikTokAppCenter,
              isActive: true,
            },
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'Payoneer' }),
              link: this.guideToTopUpPayoneer,
              isHide: this.$route.query.tab !== 'payoneer' || !this.guideToTopUpPayoneer || this.isTikTokAppCenter,
              isActive: true,
            },
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'Wise' }),
              link: this.guideToTopUpTransferWise,
              isHide: this.$route.query.tab !== 'wise' || !this.guideToTopUpTransferWise || this.isTikTokAppCenter,
              isActive: true,
            },
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'Credit card' }),
              link: this.guideToTopUpCreditCard,
              isHide: this.$route.query.tab !== 'creditcard' || !this.guideToTopUpCreditCard || this.isTikTokAppCenter,
              isActive: true,
            },
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'USDT' }),
              link: this.guideToTopUpUSDT,
              isHide: this.$route.query.tab !== 'usdt' || !this.guideToTopUpUSDT || this.isTikTokAppCenter,
              isActive: true,
            },
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'Airwallex' }),
              link: this.guideToTopUpAirwallex,
              isHide: this.$route.query.tab !== 'airwallex' || !this.guideToTopUpAirwallex || this.isTikTokAppCenter,
              isActive: true,
            },
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'Tazapay' }),
              link: this.guideToTopUpTazapay,
              isHide: this.$route.query.tab !== 'tazapay' || !this.guideToTopUpTazapay || this.isTikTokAppCenter,
              isActive: true,
            },
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'LianLian' }),
              link: this.guideToTopUpLianLian,
              isHide: this.$route.query.tab !== 'lianlian' || !this.guideToTopUpLianLian || this.isTikTokAppCenter,
              isActive: true,
            },
            {
              guide: this.$t('userGuide.clickToKickstart'),
              description: this.$t('userGuide.guideToPaymentViaMethod', { paymentMethod: 'Local bank' }),
              link: this.guideToTopUpLocalBank,
              isHide: this.$route.query.tab !== 'local-bank' || !this.guideToTopUpLocalBank || this.isTikTokAppCenter,
              isActive: true,
            },
          ],
          listQuestion: [
            {
              question: this.$t('userGuide.howToActivateCreditCard'),
              link: this.activeYourCreditCard,
              isHide: this.$route.query.tab !== 'creditcard' || !this.activeYourCreditCard,
            },
            {
              question: this.$t('userGuide.notReceivedCodeIssue'),
              link: 'https://docs.ecomdymedia.com/ecomdy-platform/flow/payment/credit-card/#do-not-receive-otp-code',
              isHide: this.$route.query.tab !== 'creditcard',
            },
          ],
        },
        {
          id: 'my-ads-account',
          title: this.$t('userGuide.howItWorks'),
          titleQuestion: this.$t('userGuide.mostViewedAdAccountArticles'),
          listGuide: [
            {
              guide: this.$t('userGuide.step1TikTokForBusinessAccount'),
              description: this.$t('userGuide.guideToConnectTikTokBusinessAccount'),
              link: this.guideToConnectTikTokAccount,
              isActive: !this.hasConnectTiktok,
              isHide: !this.guideToConnectTikTokAccount,
            },
            {
              guide: this.$t('userGuide.step2TikTokForBusinessCenter'),
              description: this.$t('userGuide.guideToCreateNewBusinessCenter'),
              link: this.guideToCreateBusinessCenter,
              isActive: this.hasConnectTiktok && !this.hasBusinessAccount,
              isHide: !this.guideToCreateBusinessCenter,
            },
            {
              guide: this.$t('userGuide.step3TikTokAdAccount'),
              description: this.$t('userGuide.guideToCreateAdAccount'),
              link: this.guideToCreateAdAccount,
              isActive: this.hasConnectTiktok && this.hasBusinessAccount,
              isHide: !this.guideToCreateAdAccount,
            },
          ],
          listQuestion: [
            {
              question: this.$t('userGuide.howToCreateAdAccount'),
              link: 'https://docs.ecomdymedia.com/ecomdy-platform/flow/get-account#1-how-to-create-tiktok-ad-account',
            },
            {
              question: this.$t('userGuide.connectingTikTokBusinessFail'),
              link: 'https://docs.ecomdymedia.com/faq/ad-account/question-1',
            },
            {
              question: this.$t('userGuide.unableToCreateAdAccount'),
              link: 'https://docs.ecomdymedia.com/faq/ad-account/question-2',
            },
          ],
        },
      ]
    },

    guides() {
      return data => data?.filter(item => !item.isHide)
    },

    questions() {
      return data => data?.filter(item => !item.isHide)
    },

    hasUserGuide() {
      const userGuides = this.listUserGuide.find(item => this.$route.name === item.id)
      return this.guides(userGuides?.listGuide)?.length > 0 || this.questions(userGuides?.listQuestion)?.length > 0
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },
  },

  methods: {
    goTo(url) {
      window.open(url)
    },

    handleShowDrawer() {
      this.isClick = true
      this.showDrawer = !this.showDrawer
    },
  },
}
</script>
<style lang="scss" scoped>

.text-learn-more {
  width: max-content;
}

.text-title {
  font-size: 20px;
  @media (min-width: 1200px) and (max-width: 1250px) {
    font-size: 18px;
  }
}

.user-guide-content {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  padding: 24px 28px 24px 24px;

  @media (max-width: 820px) {
    padding: 24px 20px 24px 24px;
  }

  @media(max-width: 991px) {
    height: 100vh;
    border-radius: var(--border-radius-base) 0 0 0;
  }
}

.user-guide-title {
  color: rgba(22, 33, 62, 0.9);
}

.user-guide-title-active {
  //background: -webkit-linear-gradient(128.33deg, #81FFF4, #6E44FF);
  //-webkit-background-clip: text !important;
  //-webkit-text-fill-color: transparent;
  color: rgba(38, 103, 255, 0.8);
}

.step-guide-content {
  //background: linear-gradient(#fff, #fff) padding-box,
  //  linear-gradient(128.33deg, #6E44FF, #81FFF4) border-box;
  border: solid 1px rgba(3,27,78,.1);
  padding: 10px;
  border-radius: 10px;
  transition: .3s ease all;

  .setting-icon {
    margin-top: 4px;
    margin-right: 8px;
    color: rgba(22, 33, 62, 0.9);
  }

  .setting-icon-active {
    color: rgba(38, 103, 255, 0.8);
  }

  .guide-description {
    margin-top: 8px;
  }
}

.step-guide-content:hover {
  box-shadow: rgba(3,27,78,.1)0 10px 20px!important;
  background-color: #fff !important;
  transform: scale(1.01);
}

.text-question {
  margin-bottom: 2px;
  font-size: 14px;
}

.question {
  color: rgb(38, 103, 255);
}

.text-question:hover {
  text-decoration: underline;
}

.user-guide-container {
  @media(max-width: 991px) {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: calc(20% - 100vw);
    height: 100vh;
    border-top-left-radius: 32px;
  }
  @media(max-width: 767px) {
    width: 80%;
  }
  @media(min-width: 767px ) and (max-width: 991px) {
    width: 70%;
  }
}

.show-drawer {
  @media(max-width: 991px) {
    right: 0px;
    box-shadow: 0 0 0 100vmax rgba(34, 41, 47, 0.5);
    animation-name: move_left;
    animation-duration: .3s;

    .user-guide-scroll {
      height: calc(100vh - 100px);
      overflow-y: scroll;
    }

    .user-guide-scroll::-webkit-scrollbar {
      display: none;
    }
  }
}

.hide-drawer {
  @media(max-width: 991px) {
    right: calc(20% - 100vw);
    animation-name: move_right;
    animation-duration: .3s;
  }
}

@keyframes move_left{
  0%{
    right: calc(30% - 100vw);
    box-shadow: 0 0 0 100vmax rgba(34, 41, 47, 0);
  }
  100%{
    right: 0px;
    box-shadow: 0 0 0 100vmax rgba(34, 41, 47, 0.5);
  }
}

@keyframes move_right {
  0%{
    right: 0px;
    box-shadow: 0 0 0 100vmax rgba(34, 41, 47, 0.5);
  }
  100%{
    right: calc(20% - 100vw);
    box-shadow: 0 0 0 110vmax rgba(34, 41, 47, 0);
  }
}
</style>
